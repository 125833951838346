import React from 'react';
import styled from 'styled-components';
import summerpicture from '../assets/images/zomer-seizoenen.jpg';
import winterpicture from '../assets/images/winter-seizoenen.jpg';
import Layout from '../components/Layout';

const Bodystyles = styled.div`
  .grid-container {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 450px);
    grid-template-rows: 1fr;
    grid-gap: 0px 50px;
    margin-top: 60px;
  }

  .grid-container img {
    justify-content: end;
  }

  .main {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .main__title {
    text-align: center;
    justify-content: center;
    font-weight: 700;
    font-size: 70px;
    color: #14284e;
    margin-top: 150px;
  }

  .summer-title {
    text-align: center;
    font-weight: 700;
    font-size: 35px;
    color: #14284e;
  }

  .summer {
    background-color: #e8f0fb;
    height: 900px;
  }

  .summer p {
    line-height: 30px;
    color: #14284e;
    width: 350px;
    justify-self: start;
  }

  .summer p,
  .winter p {
    margin-top: 0;
  }

  .bold {
    font-weight: 700;
    font-size: 18px;
  }

  .summer img {
    object-fit: cover;
    height: 632px;
    width: 460px;
    justify-self: flex-end;
    pointer-events: none;
    user-select: none;
  }

  .winter {
    background-color: #ffffff;
    height: 900px;
  }

  .winter-title {
    text-align: center;
    font-weight: 700;
    font-size: 35px;
    color: #14284e;
  }

  .winter p {
    line-height: 30px;
    color: #14284e;
    width: 350px;
    height: 600px;
    justify-self: end;
  }

  .winter img {
    object-fit: cover;
    height: 632px;
    width: 460px;
    pointer-events: none;
    user-select: none;
  }

  @media (max-width: 1200px) {
    .grid-container {
      grid-gap: 0px;
    }
  }

  @media (max-width: 997px) {
    .grid-container {
      grid-gap: 0px;
      justify-items: center;
    }

    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }

    .summer,
    .winter {
      height: 1550px;
    }

    .grid-container p {
      justify-self: center;
    }

    .summerpicture {
      justify-self: center !important;
      margin-top: 50px;
    }

    .winterpicture {
      margin-bottom: 50px;
    }
  }

  @media (max-width: 500px) {
    .summerpicture {
      margin-top: 50px;
    }

    h1 {
      font-size: 50px !important;
      margin-bottom: 50px;
    }
    p {
      width: 300px !important;
      font-size: 14px;
    }
    .summer,
    .winter {
      height: 1150px;
    }
    img {
      width: 300px !important;
      height: 250px !important;
    }
  }
`;

const Seizoenen = () => (
  <Layout>
    <Bodystyles>
      <div className="main">
        <h1 className="main__title">Seizoenen</h1>
        <div className="summer">
          <h2 className="summer-title">Zomer</h2>
          <div className="grid-container">
            <p>
              Ons appartement in Brixen is ook open in de Zomer! In de zomer
              zijn er veel zomerse activiteiten in de omgeving.
              {' '}
              <br />
              <br />
              <span className="bold">Wandelen</span>
              <br />
              Vanuit Brixen im Thale heb je toegang tot het complete
              wandelnetwerk van het Brixental. Je vindt hier meer dan 500
              kilometer aan paden. Er zijn wandelingen door het dal en in de
              bergen, waarbij je kunt kiezen tussen themawandelingen,
              meerdaagse wandelroutes, huttentochten en mooie dagtochten.
              <br />
              <br />
              <span className="bold">Zwemmen</span>
              <br />
              Brixen heeft een heel leuk zwembad met een lekkere glijbaan,
              stoei plateau en klimtouwen en een heel leuk peuterbad. Achter
              het zwembad, binnen het zwembadcomplex, ligt een groot
              natuurmeer met hoge en lage springplanken en vlonders om op te
              zonnen.
            </p>
            <img
              draggable="false"
              className="summerpicture"
              src={summerpicture}
              alt="Brixen in de zomer"
            />
          </div>
        </div>
        <div className="winter">
          <h2 className="winter-title">Winter</h2>
          <div className="grid-container">
            <img
              draggable="false"
              className="winterpicture"
              src={winterpicture}
              alt=""
            />
            <p>
              Ons appartement is een perfect verblijfplek in de winter. Van
              skien tot wandelen, je kunt hier alles doen.
              <br />
              <br />
              <span className="bold">Skien</span>
              <br />
              In de winter zijn er goede wintersport mogelijkheden. Brixen
              behoort tot het grootste aaneengesloten skigebied van
              Oostenrijk. Het skigebied heeft een hoog percentage aan
              sneeuwzekerheid door de vele sneeuwkanonnen.
              <br />
              <br />
              <span className="bold">Wandelen</span>
              <br />
              Vanuit Brixen im Thale heb je toegang tot het complete
              wandelnetwerk van het Brixental. Je vindt hier meer dan 500
              kilometer aan paden. Er zijn wandelingen door het dal en in de
              bergen, waarbij je kunt kiezen tussen themawandelingen,
              meerdaagse wandelroutes, huttentochten en mooie dagtochten.
            </p>
          </div>
        </div>
      </div>
    </Bodystyles>
  </Layout>
);

export default Seizoenen;
